import * as imageDictionary from 'models/insurances/assets/img';
import { InsuranceTypes } from 'models/insurances/types';

interface Sizes {
  '1x': string;
  '2x': string;
  '3x': string;
}

export const imageSizesTypeMapping: Record<InsuranceTypes, Sizes> = {
  HOUSEHOLD: {
    '1x': imageDictionary.household,
    '2x': imageDictionary.household2x,
    '3x': imageDictionary.household3x,
  },
  PUBLIC_HEALTH: {
    '1x': imageDictionary.publicHealth,
    '2x': imageDictionary.publicHealth2x,
    '3x': imageDictionary.publicHealth3x,
  },
  PRIVATE_HEALTH: {
    '1x': imageDictionary.privateHealth,
    '2x': imageDictionary.privateHealth2x,
    '3x': imageDictionary.privateHealth3x,
  },
  LIABILITY: {
    '1x': imageDictionary.liability,
    '2x': imageDictionary.liability2x,
    '3x': imageDictionary.liability3x,
  },
  LIFE: {
    '1x': imageDictionary.life,
    '2x': imageDictionary.life2x,
    '3x': imageDictionary.life3x,
  },
  LIFE_V2: {
    '1x': imageDictionary.life,
    '2x': imageDictionary.life2x,
    '3x': imageDictionary.life3x,
  },
  INCOMING: {
    '1x': imageDictionary.expat,
    '2x': imageDictionary.expat2x,
    '3x': imageDictionary.expat3x,
  },
  EXPAT_V2: {
    '1x': imageDictionary.expat,
    '2x': imageDictionary.expat2x,
    '3x': imageDictionary.expat3x,
  },
  LEGAL: {
    '1x': imageDictionary.legal,
    '2x': imageDictionary.legal2x,
    '3x': imageDictionary.legal3x,
  },
  LEGAL_V2: {
    '1x': imageDictionary.legal,
    '2x': imageDictionary.legal2x,
    '3x': imageDictionary.legal3x,
  },
  DENTAL: {
    '1x': imageDictionary.dental,
    '2x': imageDictionary.dental2x,
    '3x': imageDictionary.dental3x,
  },
  DENTAL_V2: {
    '1x': imageDictionary.dental,
    '2x': imageDictionary.dental2x,
    '3x': imageDictionary.dental3x,
  },
  BIKE: {
    '1x': imageDictionary.bike,
    '2x': imageDictionary.bike2x,
    '3x': imageDictionary.bike3x,
  },
  COMPANY_LIFE: {
    '1x': imageDictionary.life,
    '2x': imageDictionary.life2x,
    '3x': imageDictionary.life3x,
  },
  COMPANY_HEALTH: {
    '1x': imageDictionary.health,
    '2x': imageDictionary.health2x,
    '3x': imageDictionary.health3x,
  },
  CAR: {
    '1x': imageDictionary.car,
    '2x': imageDictionary.car2x,
    '3x': imageDictionary.car3x,
  },
  DOG_LIABILITY: {
    '1x': imageDictionary.dogLiability,
    '2x': imageDictionary.dogLiability2x,
    '3x': imageDictionary.dogLiability3x,
  },
  PET_HEALTH: {
    '1x': imageDictionary.petHealth,
    '2x': imageDictionary.petHealth2x,
    '3x': imageDictionary.petHealth3x,
  },
  DISABILITY: {
    '1x': imageDictionary.disability,
    '2x': imageDictionary.disability2x,
    '3x': imageDictionary.disability3x,
  },
  TRAVEL: {
    '1x': imageDictionary.travel,
    '2x': imageDictionary.travel2x,
    '3x': imageDictionary.travel3x,
  },
  GENERIC: {
    '1x': imageDictionary.generic,
    '2x': imageDictionary.generic2x,
    '3x': imageDictionary.generic3x,
  },
  PENSION: {
    '1x': imageDictionary.pension,
    '2x': imageDictionary.pension2x,
    '3x': imageDictionary.pension3x,
  },
  BASIS_PENSION: {
    '1x': imageDictionary.basisPension,
    '2x': imageDictionary.basisPension2x,
    '3x': imageDictionary.basisPension3x,
  },
  JOB: {
    '1x': imageDictionary.generic,
    '2x': imageDictionary.generic2x,
    '3x': imageDictionary.generic3x,
  },
  INCOMING_ES: {
    '1x': imageDictionary.expat,
    '2x': imageDictionary.expat2x,
    '3x': imageDictionary.expat3x,
  },
  TRAVEL_HEALTH_V1: {
    '1x': imageDictionary.generic,
    '2x': imageDictionary.generic2x,
    '3x': imageDictionary.generic3x,
  },
  TRAVEL_FR: {
    '1x': imageDictionary.travel,
    '2x': imageDictionary.travel2x,
    '3x': imageDictionary.travel3x,
  },
  BIKE_V2: {
    '1x': imageDictionary.bike,
    '2x': imageDictionary.bike2x,
    '3x': imageDictionary.bike3x,
  },
  INCOMING_EU: {
    '1x': imageDictionary.expat,
    '2x': imageDictionary.expat2x,
    '3x': imageDictionary.expat3x,
  },
  INCOMING_LT: {
    '1x': imageDictionary.expat,
    '2x': imageDictionary.expat2x,
    '3x': imageDictionary.expat3x,
  },
  DISABILITY_V2: {
    '1x': imageDictionary.disability,
    '2x': imageDictionary.disability2x,
    '3x': imageDictionary.disability3x,
  },
  COMPANY_PENSION: {
    '1x': imageDictionary.pension,
    '2x': imageDictionary.pension2x,
    '3x': imageDictionary.pension3x,
  },
  DOG_LIABILITY_ES: {
    '1x': imageDictionary.generic,
    '2x': imageDictionary.generic2x,
    '3x': imageDictionary.generic3x,
  },
};

export const getImageSrc = (
  insuranceType: InsuranceTypes
): { src: string; srcSet: string; sizes: string } => ({
  src: imageSizesTypeMapping[insuranceType]['2x'],
  srcSet: `${imageSizesTypeMapping[insuranceType]['1x']} 152w, ${imageSizesTypeMapping[insuranceType]['2x']} 304w, ${imageSizesTypeMapping[insuranceType]['3x']} 457w`,
  sizes: '(max-width: 34rem) 72px, (max-width: 45rem) 116px, 160px',
});
