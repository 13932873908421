import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';
import {
  BottomOrRegularModal,
  Button,
  CurrencyInput,
  InfoIcon,
  Input,
} from '@popsure/dirty-swan';
import classNames from 'classnames';
import Markdown from 'components/markdown';
import { SliderWithModal } from 'components/SliderWithModal';
import {
  AGE_LOWER_LIMIT,
  AGE_UPPER_LIMIT,
  DEFAULT_PENSION_AGE,
  MAXIMUM_MONTHLY_PAYMENT,
  MINIMUM_MONTHLY_PAYMENT,
  SLIDER_STEP_SIZE,
  YEARLY_YIELD_PERCENTAGE,
} from 'features/pension/constants';
import { CalculatorError, Quote } from 'features/pension/models';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSafeTranslation } from 'shared/i18n';

import styles from './Calculator.module.scss';

type CalculatorProps = {
  setQuote: React.Dispatch<React.SetStateAction<Quote>>;
  setErrorType: React.Dispatch<React.SetStateAction<CalculatorError | null>>;
  age: number | null;
  initialAmount: number;
  monthlyPayment: number;
  errorType: CalculatorError | null;
  calculatedPension: string | null;
  handleSubmitQuote: () => void;
};

export const CalculatorView = ({
  setQuote,
  setErrorType,
  age,
  initialAmount,
  monthlyPayment,
  errorType,
  calculatedPension,
  handleSubmitQuote,
}: CalculatorProps) => {
  const { t } = useSafeTranslation();

  const [openModal, setOpenModal] = useState(false);

  const errorMessageMapping: Record<
    CalculatorError,
    { title?: string; description: string }
  > = {
    AGE_ABOVE_LIMIT: {
      title: t(
        'pension.qnr.quote.calculator.ageUpperLimit.title',
        'You need to be under {{maxAge}} years old',
        { maxAge: AGE_UPPER_LIMIT }
      ),
      description: t(
        'pension.qnr.quote.calculator.ageUpperLimit.description',
        'We can only offer this pension plan for people who are under {{maxAge}} years old.',
        { maxAge: AGE_UPPER_LIMIT }
      ),
    },
    AGE_BELOW_LIMIT: {
      title: t(
        'pension.qnr.quote.calculator.ageLowerLimit.title',
        'You need to be over {{minAge}} years old',
        { minAge: AGE_LOWER_LIMIT }
      ),
      description: t(
        'pension.qnr.quote.calculator.ageLowerLimit.description',
        'We can only offer this pension plan for people who are over {{minAge}} years old.',
        { minAge: AGE_LOWER_LIMIT }
      ),
    },
    INITIAL_AMOUNT_ABOVE_LIMIT: {
      title: t(
        'pension.qnr.quote.calculator.amountLimit.title',
        'Please adjust your one-time payment'
      ),
      description: t(
        'pension.qnr.quote.calculator.amountLimit.description',
        'The one-time payment must be less than €1 million.'
      ),
    },
    MISSING_VALUES: {
      title: t(
        'pension.qnr.quote.calculator.missingValues.title',
        'Please provide age and monthly contribution'
      ),
      description: t(
        'pension.qnr.quote.calculator.missingValues.description',
        'To calculate you pension, we need your age and monthly contribution.'
      ),
    },
    SUBMISSION_ERROR: {
      title: t(
        'pension.qnr.quote.calculator.error.title',
        "Something didn't work"
      ),
      description: t(
        'pension.qnr.quote.calculator.error.description',
        'To calculate you pension, we need your age and monthly contribution.'
      ),
    },
  };

  const errorMessage = errorType ? errorMessageMapping[errorType] : null;

  return (
    <>
      <section className={classNames('d-flex fd-column ai-center mt24 mb72')}>
        <div
          className={classNames(
            'd-flex fd-column jc-center ai-center',
            styles.resultContainer
          )}
        >
          <p className="p-p--small tc-grey-500">
            {t('pension.qnr.quote.calculator.result.approx', 'Approximately')}
          </p>
          <h3 className="p--serif p-h1--xl tc-primary-500">
            {calculatedPension}
          </h3>
        </div>
        <div
          className={classNames(
            'd-flex jc-between ai-center fd-column bg-white br16 p32 wmx10 w100 mt24',
            styles.calculatorContainer
          )}
        >
          <div
            className={classNames(
              'd-flex jc-between fd-column w100',
              styles.calculatorFormContainer
            )}
          >
            <div className="w100">
              <div
                className={classNames(
                  'd-flex gap16 fd-column',
                  styles.inputContainer
                )}
              >
                <div className={classNames(styles.ageInput)}>
                  <Input
                    className="mt8 w100"
                    placeholder="30"
                    value={age ? String(age) : ''}
                    onChange={(e) => {
                      setQuote((prevQuote) => ({
                        ...prevQuote,
                        age: Number(e.target.value),
                      }));
                      setErrorType(null);
                    }}
                    type="number"
                    id="pension-insurance-calculator-age"
                    label={t('pension.qnr.quote.calculator.age.label', 'Age')}
                    data-cy="input-age"
                  />
                </div>
                <div className={classNames(styles.oneTimePaymentInput)}>
                  <CurrencyInput
                    className="mt8 w100"
                    placeholder="0"
                    value={initialAmount}
                    onChange={(value) => {
                      setQuote((prevQuote) => ({
                        ...prevQuote,
                        initialAmount: value,
                      }));
                      setErrorType(null);
                    }}
                    id="pension-insurance-calculator-one-time-payment"
                    label={t(
                      'pension.qnr.quote.calculator.oneTimePayment.label',
                      'One-time payment'
                    )}
                    data-cy="input-initialAmount"
                  />
                </div>
              </div>
              <div
                className={classNames(
                  'mt32 w100',
                  styles.monthlyContributionsInput
                )}
              >
                <SliderWithModal
                  label={t(
                    'pension.qnr.quote.calculator.monthlyContributions.label',
                    'Monthly contributions'
                  )}
                  step={SLIDER_STEP_SIZE}
                  min={MINIMUM_MONTHLY_PAYMENT}
                  max={MAXIMUM_MONTHLY_PAYMENT}
                  setValue={(value) => {
                    setQuote((prevQuote) => ({
                      ...prevQuote,
                      monthlyPayment: value,
                    }));
                  }}
                  value={monthlyPayment}
                  modalTextOverrides={{
                    title: t(
                      'pension.qnr.quote.calculator.monthlyContributions.modal.title',
                      'Monthly contributions'
                    ),
                    description: t(
                      'pension.qnr.quote.calculator.monthlyContributions.modal.description',
                      {
                        defaultValue:
                          'Please enter your desired monthly contribution ranging from {{min}} to {{max}}.',
                        min: englishFormattedEuroCurrency(
                          MINIMUM_MONTHLY_PAYMENT,
                          true
                        ),
                        max: englishFormattedEuroCurrency(
                          MAXIMUM_MONTHLY_PAYMENT,
                          true
                        ),
                      }
                    ),
                    placeholder: t(
                      'pension.qnr.quote.calculator.monthlyContributions.modal.placeholder',
                      'Monthly contributions'
                    ),
                    belowMinErrorMessage: t(
                      'pension.qnr.quote.calculator.monthlyContributions.modal.belowmin',
                      {
                        defaultValue:
                          'The insured sum exceeds the minimum amount {{min}}',
                        min: englishFormattedEuroCurrency(
                          MINIMUM_MONTHLY_PAYMENT,
                          true
                        ),
                      }
                    ),
                    aboveMaxErrorMessage: t(
                      'pension.qnr.quote.calculator.monthlyContributions.modal.abovemax',
                      {
                        defaultValue:
                          'The insured sum exceeds the maximum amount {{max}}',
                        max: englishFormattedEuroCurrency(
                          MAXIMUM_MONTHLY_PAYMENT,
                          true
                        ),
                      }
                    ),
                    buttonCaption: t(
                      'pension.qnr.quote.calculator.monthlyContributions.modal.button.caption',
                      'Apply'
                    ),
                  }}
                />
              </div>
              <button
                type="button"
                onClick={() => setOpenModal(true)}
                className="p-a fw-bold bg-transparent c-pointer mt32"
              >
                {t(
                  'pension.qnr.quote.calculator.contributionModal.button.label',
                  'How to choose monthly contributions'
                )}
              </button>
            </div>
          </div>
          <AnimateHeight duration={300} height={errorMessage ? 'auto' : 0}>
            <div
              className={classNames(
                'd-flex ai-start c-gap8 bg-grey-100 br8 mt40 wmx6 p16 mxauto',
                styles.infoBox
              )}
            >
              <InfoIcon
                size={20}
                noMargin
                className={classNames(styles.infoIcon)}
              />
              <div>
                <h4 className="p-h4">{errorMessage?.title}</h4>
                <p className="p-p mt8 tc-grey-700">
                  {errorMessage?.description}
                </p>
              </div>
            </div>
          </AnimateHeight>
        </div>
        <>
          <Button
            className={classNames(
              'p-btn p-btn--primary ws4 mt24',
              styles.signUpLink
            )}
            onClick={handleSubmitQuote}
            disabled={Boolean(errorMessage)}
            data-cy="button"
          >
            {t('pension.qnr.quote.calculator.button.caption', 'Sign up')}
          </Button>
          <p className="mt16 p-p--small tc-grey-500 ws4 ta-center">
            {t(
              'pension.qnr.quote.calculator.result.details',
              'Your pension is calculated based on an average profit of 6% per year until the age of 67, including fees.',
              {
                yearlyYieldPercentage: String(YEARLY_YIELD_PERCENTAGE).concat(
                  '%'
                ),
                pensionAge: DEFAULT_PENSION_AGE,
              }
            )}
          </p>
        </>
      </section>
      <BottomOrRegularModal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        title={t(
          'pension.qnr.quote.calculator.contributionModal.title',
          'How to choose monthly contributions'
        )}
      >
        <Markdown className="p-p px24 pb24">
          {t(
            'pension.qnr.quote.calculator.contributionModal.description',
            'We recommend a monthly contribution of approximately 10% of your income after taxes.\n\n**Can I change my monthly contributions later?** Yes, you can increase, decrease, pause your contributions, or even withdraw money later.'
          )}
        </Markdown>
      </BottomOrRegularModal>
    </>
  );
};
